<template>
    <div style="display:flex; justify-content: center;">

        <v-card class="" style="min-width:216px; width:216px; padding:8px;">
            <div v-if="avatarMode=='view'" class="">
                <span v-if="avatarUrl" class="">
                    <img :src="avatarUrl" style="height:200px; width:200px; border: 2px solid white; border-radius: 100px;" />
                </span>
                <span v-if="!avatarUrl" style="display:flex; justify-content: center;">
                    <CustomIcon :size="30" glyph="mdi-account" /> 
                </span>
                <button @click="avatarMode='edit';avatarChangeStatus=null" style="width:100%">
                    Change Avatar
                </button>
            </div>
            <div v-if="avatarMode=='edit'" style="display:flex; flex-direction: column; align-items: center;">
                <picture-input 
                    ref="pictureInput"
                    width="600" 
                    height="600" 
                    margin="16" 
                    accept="image/jpeg,image/png" 
                    size="10" 
                    button-class="btn"
                    :custom-strings="{
                    upload: '<h1>Error</h1>',
                    drag: 'Browse or drag an image'
                    }"
                    @change="onChange">
                </picture-input>

                <!-- <button @click="attemptUpload" v-bind:class="{ disabled: !image }" style="width:100%">
                Upload
                </button> -->
                <v-btn @click="attemptUpload" color="green white--text" style="margin-top:8px; width:100px">
                UPLOAD
                </v-btn>

            </div>
            <div v-if="avatarChangeStatus=='success'" class="mt-10" style="display:flex; flex-direction: column; align-items: center;">
                Avatar Changed!
            </div>
            <div v-if="avatarChangeStatus=='error'" class="mt-10" style="display:flex; flex-direction: column; align-items: center;">
                Error saving avatar
            </div>

        </v-card>

    </div>
</template>
<script>
import PictureInput from 'vue-picture-input';
// import fileUpload from '/src/utils/fileupload';
import api from '/src/utils/api';


export default {
    name: 'app',
    components: {
      CustomIcon: () => import("/src/components/CustomIcon.vue"),
      PictureInput
    },
    data () {
        return {
        image: null,
        avatarMode: "view",
        avatarUrl: null,
        avatarChangeStatus: null,
        }
    },
    mounted() {
        this.avatarUrl = localStorage.getItem('avatarLargeUrl');
    },
    methods: {
        onChange() {
            // console.log("New picture loaded");
            if (this.$refs.pictureInput.file) {
                this.image = this.$refs.pictureInput.file;
            }
            else {
                console.log("Old browser. No support for Filereader API");
            }
        },
        onRemoved() {
            this.image = '';
        },
        attemptUpload() {
            if (this.image) {
                console.log("attemptUpload this.image", this.image);
                api.setAvatar( this.image)
                .then(response=>{
                    this.image = '';
                    this.avatarUrl = response.picture;
                    this.avatarMode = "view";
                    this.avatarChangeStatus = "success";
                })
                .catch(err=>{
                    this.avatarChangeStatus = "error";
                    console.error("ERRORR:",err);
                });
            }
        }
    }
}
</script>